.navbar {
    .navbar-container {
        height: 60px;
        margin-top: 30px;
        display: flex;
        align-content: center;
        align-items: flex-start;
        justify-content: space-between;
        border-color: none none linear-gradient(270deg, rgba(186, 132, 88, 0.26) 0%, #BA8458 19.21%, #C19A59 37.72%, #6EABBD 82.83%, rgba(110, 171, 189, 0.24) 100%) none;

        img {
            width: 100px;
            height: auto;
        }

        .ul {
            /* Auto layout */

            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0px;
            gap: 70px;
            margin-top: 4px;
            margin-bottom: 0;


            .menu-items {
                // width: 74px;
                // height: 20px;

                display: flex;
                align-items: center;

                button {
                    width: auto;
                    height: auto;
                    border: none;
                    display: list-item;
                    padding: 0;
                    line-height: initial;
                    background-color: transparent;
                }

                button.submenu {
                    text-decoration: underline;
                    text-decoration-color: white;
                }

                .dropdown {
                    display: none;
                }

                .dropdown.show {
                    display: flex;
                    padding: 0;
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    top: 60px;
                    gap: 16px;
                    padding-top: 16px;

                    .menu-item {
                        background-color: #242424;
                    }
                }
            }

            a,
            button {
                &:hover {
                    background: linear-gradient(90deg, #71D1F2 0%, #C7C66D 32.01%, #F4BB49 55.07%, #EB874E 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .navigation {
            display: none;
        }
    }

    hr {
        margin: 0;
        height: 2px;
        border: none;
        background: linear-gradient(270deg, rgba(186, 132, 88, 0.26) 0%, #BA8458 19.21%, #C19A59 37.72%, #6EABBD 82.83%, rgba(110, 171, 189, 0.24) 100%);
    }

    .link {
        text-decoration: none;
        color: #FFFFFF;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.32px;
        text-align: left;
    }

    @media all and (max-width: 1200px) {
        padding: 0 15px;

        .navbar-container {
            ul {
                gap: 35px;
            }
        }
    }

    @media all and (max-width: 848px) {
        .navbar-container {
            padding: 30px 0 20px 0;
            height: auto;
            margin-top: 0;
            border-color: none;
            align-items: center;

            .ul {
                display: none;
            }

            .navigation {
                display: block;
                cursor: pointer;

                img {
                    height: 37px;
                    width: auto;
                }

                .navig-ul {
                    z-index: 2;
                    position: absolute;
                    right: 0;
                    list-style: none;
                    padding: 30px 2em;
                    margin: 0;
                    border: none;
                    top: 0;
                    background: #242424;
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                    padding-bottom: 100%;
                }
            }
        }
    }
}