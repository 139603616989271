@mixin twoColumns {
    .element-0 {
        border-radius: 30px 0px 0px 0px;

        .background {
            border-radius: 30px 0px 0px 0px;

            .inside {
                border-radius: 30px 0px 0px 0px;
            }

        }
    }

    .element-1 {
        border-radius: 0px 30px 0px 0px;

        .background {
            border-radius: 0px 30px 0px 0px;

            .inside {
                border-radius: 0px 30px 0px 0px;
            }

        }
    }

    .element-4 {
        border-radius: 0px 0px 0px 30px;

        .background {
            border-radius: 0px 0px 0px 30px;

            .inside {
                border-radius: 0px 0px 0px 30px;
            }

        }
    }

    .element-5 {
        border-radius: 0px 0px 30px 0px;

        .background {
            border-radius: 0px 0px 30px 0px;

            .inside {
                border-radius: 0px 0px 30px 0px;
            }

        }
    }

    .element-2,
    .element-9,
    .element-11 {
        border-radius: 0;

        .background {
            border-radius: 0;

            .inside {
                border-radius: 0;
            }

        }
    }
}

@mixin oneColumn {
    .element-0 {
        border-radius: 30px 30px 0px 0px;

        .background {
            border-radius: 30px 30px 0px 0px;

            .inside {
                border-radius: 30px 30px 0px 0px;
            }

        }
    }

    .element-5 {
        border-radius: 0px 0px 30px 30px;

        .background {
            border-radius: 0px 0px 30px 30px;

            .inside {
                border-radius: 0px 0px 30px 30px;
            }

        }
    }

    .element-1,
    .element-2,
    .element-4,
    .element-9,
    .element-11 {
        border-radius: 0;

        .background {
            border-radius: 0;

            .inside {
                border-radius: 0;
            }

        }
    }
}

.our-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    color: white;



    .our-projects-inside {
        display: flex;
        gap: 60px;
        flex-direction: column;

        .grid-container {
            display: grid;
            grid-template-columns: auto auto auto;
            grid-template-rows: auto auto auto auto;
            gap: 25px;

            .grid-item {
                width: 356px;
                height: 460px;
                background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .background {
                    width: 352px;
                    height: 456px;
                    background: #242423;

                    &:hover {
                        background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                    }

                    .inside {
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #333330 0%, rgba(51, 51, 48, 0) 100%);

                        &:hover {
                            background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                            transform: scale(1.05);
                        }

                        .content {
                            padding-top: 38px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            h4 {
                                height: 68px;
                                text-align: center;
                            }

                            .PreviewProduct {
                                width: 192px;
                                height: 228px;
                                margin-top: 15px;
                                margin-bottom: 33px;
                                overflow: hidden;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }

            .element-0 {
                border-radius: 60px 0px 0px 0px;

                .background {
                    border-radius: 60px 0px 0px 0px;

                    .inside {
                        border-radius: 60px 0px 0px 0px;
                    }

                }
            }

            .element-2 {
                border-radius: 0px 60px 0px 0px;

                .background {
                    border-radius: 0px 60px 0px 0px;

                    .inside {
                        border-radius: 0px 60px 0px 0px;
                    }

                }
            }

            .element-9 {
                border-radius: 0px 0px 0px 60px;

                .background {
                    border-radius: 0px 0px 0px 60px;

                    .inside {
                        border-radius: 0px 0px 0px 60px;
                    }

                }
            }

            .element-11 {
                border-radius: 0px 0px 60px 0px;

                .background {
                    border-radius: 0px 0px 60px 0px;

                    .inside {
                        border-radius: 0px 0px 60px 0px;
                    }

                }
            }
        }

        .bottom {
            display: grid;
            grid-template-columns: 356px 356px 356px;
            align-items: center;
            gap: 25px;

            h2 {
                background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;

                justify-self: right;
                cursor: pointer;
            }

            .dots {
                grid-column-start: 2;
            }
        }

        .show {
            .overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.3);
                display: grid;
                place-items: center;
                visibility: hidden;
                z-index: -10;
            }

            .open-content {
                visibility: visible;
                z-index: 10;
            }

            .container {
                background: white;
                border-radius: 60px;
                width: 926px;
                height: auto;

                .close-button {
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                    float: right;
                    position: relative;
                    top: 40px;
                    right: 63px;
                }

                .content {
                    padding: 70px 40px;
                    display: flex;
                    height: 100%;
                    align-items: center;
                    gap: 24px;

                    .left-img {
                        width: 261px;
                        height: 265px;
                        object-fit: cover;
                    }

                    .text {
                        h1 {
                            color: #333333;
                        }

                        h5 {
                            margin-top: 20px;
                            color: #A4A4A4;
                        }

                        span {
                            display: block;
                            margin-top: 28px;
                            color: #333333;
                        }

                        .images {
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 1200px) {
        gap: 15px;

        .our-projects-inside {
            .grid-container {
                grid-template-columns: auto auto;
                grid-template-rows: auto auto auto;

                .grid-item {
                    width: 300px;

                    .background {
                        width: 296px;

                        .inside {
                            .content {
                                h4 {
                                    font-size: 22px;
                                }
                            }
                        }
                    }
                }

                @include twoColumns;
            }

            .bottom {
                grid-template-columns: auto auto auto;
                gap: 0;

                h2 {
                    justify-self: auto;
                }
            }
        }
    }

    @media all and (max-width: 960px) {
        .our-projects-inside {
            .show {
                .container {
                    background: white;
                    border-radius: 30px;
                    width: 648px;

                    .close-button {
                        position: relative;
                        top: 25px;
                        right: 25px;
                        width: 14px;
                        height: 14px;
                    }

                    .content {
                        padding: 25px 15px 20px 15px;
                        display: flex;
                        flex-direction: column;
                        height: auto;
                        align-items: center;
                        gap: 16px;

                        .left-img {
                            margin-top: 25px;
                            width: 261px;
                            height: 265px;
                        }

                        .text {
                            h1 {
                                font-weight: 800;
                                font-size: 38px;
                                line-height: 46px;
                                color: #333333;
                            }

                            h5 {
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 22px;
                                margin-top: 20px;
                                color: #A4A4A4;
                            }

                            span {
                                display: block;
                                margin-top: 15px;
                                color: #333333;
                                font-weight: 500;
                                font-size: 11px;
                                line-height: 13px;
                            }

                            .images {
                                margin-top: 25px;
                                gap: 15px;

                                img {
                                    width: 130px;
                                    height: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 648px) {
        .our-projects-inside {
            gap: 15px;
            display: grid;
            justify-items: center;

            .grid-container {
                grid-template-columns: auto;
                gap: 15px;

                .grid-item {
                    height: 340px;

                    .background {
                        height: 336px;

                        .inside {
                            .content {
                                padding-top: 15px;

                                .PreviewProduct {
                                    width: 100px;
                                    height: 119px;
                                    padding-top: 14px;
                                    padding-bottom: 15px;
                                }

                                .text {
                                    .images {
                                        img {
                                            width: 60px;
                                            height: 24px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                @include oneColumn;
            }

            .bottom {
                display: flex;
                flex-direction: column;
                gap: 0;

                h2 {
                    justify-self: right;
                }
            }

            .show {
                .container {
                    background: white;
                    border-radius: 30px;
                    width: 315px;

                    .content {
                        padding: 25px 15px 20px 29px;
                        display: flex;
                        flex-direction: column;
                        height: auto;
                        align-items: center;

                        .left-img {
                            margin-top: 25px;
                            width: 261px;
                            height: 265px;
                        }

                        .text {
                            h1 {
                                font-weight: 800;
                                font-size: 38px;
                                line-height: 46px;
                                color: #333333;
                            }

                            h5 {
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 22px;
                                margin-top: 20px;
                                color: #A4A4A4;
                            }

                            span {
                                display: block;
                                margin-top: 15px;
                                color: #333333;
                                font-weight: 500;
                                font-size: 11px;
                                line-height: 13px;
                            }

                            .images {
                                margin-top: 39px;
                                gap: 15px;

                                img {
                                    width: 130px;
                                    height: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 520px) {
        .our-projects-inside {
            .grid-container {
                grid-template-columns: 165px 165px;
                grid-template-rows: 245px 245px 245px;

                .grid-item {
                    width: 165px;
                    height: 245px;

                    .background {
                        width: 161px;
                        height: 241px;

                        .inside {
                            .content {
                                h4 {
                                    font-size: 15px;
                                    height: 45px;
                                }

                                .PreviewProduct {
                                    margin-top: 14px;
                                    margin-bottom: 15px;
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }

                @include twoColumns;
            }

            .show {
                .container {
                    .content {
                        .text {
                            h1 {
                                font-weight: 800;
                                font-size: 18px;
                                line-height: 22px
                            }

                            h5 {
                                margin-top: 5px;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 22px;
                            }

                            span {
                                h6 {
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .bottom {
                display: grid;
                grid-template-columns: 115px 115px 115px;
                gap: 0;

                h2 {
                    justify-self: right;
                }
            }
        }
    }
}