.date-and-time {
    color: white;
    text-align: center;
    margin-top: 3.75em;

    .small-title {
        margin-top: 1em;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 46em;
        margin: 0 auto;
        margin-top: 2em;

        &__left,
        &__right {
            display: flex;
            flex-direction: column;
            gap: 1em;
        }

        &__right {
            a {
                color: white;
            }
        }
    }

    .gradient {
        margin: 0 auto;
        margin-top: 3.75em;
        width: 31.25em;
        // color: #333333;

        &__inner {
            width: 31em;
        }
    }

    @media all and (max-width: 750px) {
        .date-and-time__container {
            width: 100%;
            height: auto;
            justify-content: space-evenly;
        }

        .gradient {
            margin-top: 1.5em;
            width: 270px;
            // color: #333333;

            &__inner {
                width: 266px;
            }
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 1.5em;

        .small-title {
            margin-top: 0.5em;
        }

        .date-and-time__container {
            flex-direction: column;
            margin-top: 1em;
            gap: 1em;
        }
    }
}