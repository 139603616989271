.dots-content {
    margin-top: 3.75em;
    color: #E6E4E8;

    .upper-part {

        .items .item,
        .content {
            .h3-two {
                text-align: center;
            }

            .first {
                text-align: start;
            }

            .last {
                text-align: end;
            }
        }


        .items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            justify-items: stretch;
            gap: 5px;

            .item {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;

                .circle {
                    width: 6em;
                    height: 6em;
                    border-radius: 50%;

                    background: #E6E4E8;

                    display: grid;
                    align-content: center;
                    justify-content: center;

                    .inner {
                        width: 5.64em;
                        height: 5.64em;
                        border-radius: 50%;

                        background: #242423;

                        display: grid;
                        align-content: center;
                        justify-content: center;

                        h1 {
                            font-weight: 500;
                        }
                    }
                }
            }

            .active {
                .circle {
                    background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                }

                .h3-two,
                .circle.inner.h1 {
                    background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }

            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;

                line {
                    stroke-width: 2px;
                    stroke-dasharray: 20px 10px;
                    stroke: #E6E4E8;
                }
            }
        }

        .content {
            margin-top: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            height: 6em;

            .first {
                grid-column-start: 1;
                grid-column-end: 4;
            }

            .second {
                grid-column-start: 1;
                grid-column-end: 4;
            }

            .third {
                grid-column-start: 2;
                grid-column-end: 5;
            }

            .fourth {
                grid-column-start: 2;
                grid-column-end: 6;
            }

            .last {
                grid-column-start: 2;
                grid-column-end: 6;
            }
        }
    }

    .block {
        width: 46em;
        height: 7em;
        background: linear-gradient(270deg, rgba(186, 132, 88, 0.26) 0%, #BA8458 19.21%, #C19A59 37.72%, #6EABBD 82.83%, rgba(110, 171, 189, 0.24) 100%);
        display: grid;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 30px;

        .inner {
            width: 45.64em;
            height: 6.64em;
            display: grid;
            align-items: center;
            background: #242423;
            border-radius: 10px;
            text-align: center;

            .h3-two {
                padding: 34px 44px;
            }
        }
    }

    @media all and (max-width: 1115px) {
        .upper-part .items .item {
            .first {
                text-align: center;
            }

            .last {
                text-align: center;
            }
        }
    }

    @media all and (max-width: 748px) {
        .block {
            width: 30em;
            height: 7em;

            .inner {
                width: 29.64em;
                height: 6.64em;

                .h3-two {
                    padding: 5px 10px;
                }
            }
        }
    }

    @media all and (max-width: 648px) {
        .upper-part {
            .items {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                justify-items: stretch;

                .item {
                    .circle {
                        width: 3em;
                        height: 3em;

                        .inner {
                            width: 2.7em;
                            height: 2.7em;
                        }
                    }

                    img {
                        height: 50px;
                    }
                }
            }

            .content {
                margin-top: 5px;
            }
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 1.5em;

        .upper-part .content {
            height: 3em;
        }

        .block {
            margin-top: 1.5em;
            width: 20em;
            height: 6em;

            .inner {
                width: 19.64em;
                height: 5.64em;

                .h3-two {
                    padding: 0.5em 1.5em;
                }
            }
        }
    }
}