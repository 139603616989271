.about-us-page {
    margin-top: 100px;

    .landing-text {
        h1 {
            margin-bottom: 40px;
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 30px;
    }

    @import '../../hooks/landingText/LandingText',
    'components/SecondPart/SecondPart',
    'components/StepsOfDevelopment/StepsOfDevelopment',
    'components/AllSteps/AllSteps';
}