.demands-for-students {
    margin-top: 10em;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5em;

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1.5em;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1em;

            img {
                width: 10em;
                height: 10em;
            }
        }
    }

    h1,
    &__container {
        color: white;
    }

    &__plus {
        color: #E6E4E8;

        display: flex;
        align-items: center;
        gap: 2em;

        img {
            width: 2.5em;
            height: 2.5em;
        }
    }

    @media all and (max-width: 720px) {
        margin-top: 5em;
        gap: 3em;

        &__container {
            &__item {
                img {
                    width: 5em;
                    height: 5em;
                }
            }
        }

        &__plus {
            gap: 1em;

            img {
                width: 1.25em;
                height: 1.25em;
            }
        }
    }

    @media all and (max-width: 720px) {
        margin-top: 3em;
        gap: 2em;

        &__container {
            grid-template-columns: 1fr 1fr;
        }

        &__plus {
            gap: 0.5em;

            h5 {
                text-align: left;
                font-size: 11px;
                line-height: 10px;
            }
        }
    }

    @media all and (max-width: 520px) {
        &__plus {
            h5 {
                text-align: left;
                font-size: 8px;
                line-height: 10px;
            }
        }
    }
}