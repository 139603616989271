.first-part {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__left {
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 48px;

        &__upper {
            align-self: end;

            h1 {
                margin-bottom: 30px;

                background: linear-gradient(90deg, #71D1F2 0%, #C7C66D 32.01%, #F4BB49 55.07%, #EB874E 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            h2 {
                color: white;
            }
        }

        .gradient {
            width: 500px;

            &__inner {
                width: 496px;
            }
        }
    }

    img {
        width: 546px;
        height: 546px;
    }

    @media all and (max-width: 1115px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 25px;
        align-items: center;

        &__left {
            gap: 25px;
            justify-items: center;

            &__upper {
                h1 {
                    margin-bottom: 10px;
                }
            }
        }

        img {
            width: 68%;
            height: auto;
            max-width: 546px;
            max-height: 546px;
        }
    }

    @media all and (max-width: 520px) {
        &__left {
            .gradient {
                width: 270px;

                &__inner {
                    width: 266px;
                }
            }
        }

    }
}