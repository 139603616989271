.web-dev-page {
    margin-top: 70px;
    margin-bottom: 100px;

    @media all and (max-width: 1115px) {
        margin-top: 60px;
        margin-bottom: 80px;
    }

    @media all and (max-width: 520px) {
        margin-top: 30px;
        margin-bottom: 60px;
    }

    @import './components/firstPart/FirstPart',
    './components/advantagesOfZoom/AdvantagesOfZoom',
    './components/whyClientsChooseZoom/WhyClientsChooseZoom',
    './components/ourProducts/OurProducts',
    '../../hooks/transpContainer/transpContainer';

    .transp-container {
        margin-top: 100px;
        margin-bottom: 0;
        height: 739px;

        .container {
            margin-top: 120px;

            .h1-two {
                width: 864px;
                padding-top: 91px;
                display: block;
                text-align: center;

                color: white;
            }

            form {
                margin-top: 50px;
                padding-bottom: 91px;
            }
        }

        @media all and (max-width: 1200px) {
            background-size: contain;
            background-position-y: -20px;
            margin-top: 60px;
            height: 500px;

            .container {
                margin-top: 45px;
                width: 80%;

                .h1-two {
                    margin: 0 auto;
                    width: 90%;
                    padding-top: 20px;
                }

                form {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }

        @media all and (max-width: 520px) {
            margin-top: 2em;
            height: 301px;
            background-size: contain;

            .container {
                margin-top: 45px;
                width: 345px;

                .h1-two {
                    margin: 0 auto;
                    width: 315px;
                    padding-top: 20px;
                }

                form {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }
}