.transp-container {
    background-image: url('../../components/images/ContactBackground.png');
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    place-items: center;
    align-items: start;

    .container {
        background: rgba(169, 169, 169, 0.1);
        backdrop-filter: blur(12px);

        border-radius: 30px;
    }

    @media all and (max-width: 680px) {
        width: 100%;

        .container {
            width: 90%;
        }
    }
}