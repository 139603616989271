.second-part {
    margin-top: 150px;

    color: white;

    .first-container {
        display: grid;
        grid-template-columns: auto auto;

        .video {
            iframe {
                width: 576px;
                height: 324px;
                border-radius: 20px;
            }
        }

        .text {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                width: 25em;
            }
        }
    }

    .second-container {
        margin-top: 80px;
        text-align: center;

        a {
            text-decoration: none;
        }

        button {
            margin-top: 60px;
            height: 70px;
            width: 100%;
            border-radius: 20px;
            padding: 0;

            display: grid;
            grid-template-columns: 1fr 1.2fr 1fr;
            justify-items: stretch;

            color: #33629A;

            svg {
                height: 100%;
                width: 100%;

                line {
                    stroke: #33629A;
                    stroke-width: 2px;
                }
            }

            .forbes-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    @media all and (max-width: 1115px) {
        margin-top: 60px;

        .first-container {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            gap: 30px;

            .text {
                p {
                    width: 100%;
                }
            }
        }

        .second-container {
            margin-top: 60px;

            button {
                margin-top: 30px;
            }
        }
    }

    @media all and (max-width: 800px) {
        .first-container {
            .video {
                iframe {
                    width: 512px;
                    height: 288px;
                }
            }
        }

        .second-container {
            button {
                grid-template-columns: 1fr 2fr 1fr;
            }
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 50px;

        .first-container {
            gap: 15px;

            .video {
                iframe {
                    width: 256px;
                    height: 144px;
                }
            }
        }

        .second-container {
            margin-top: 30px;

            button {
                margin: 0 auto;
                margin-top: 15px;

                height: 36px;
                width: 80%;

                grid-template-columns: 1fr 4fr 1fr;

                .forbes-container {
                    img {
                        width: 50%;
                    }
                }
            }
        }
    }
}