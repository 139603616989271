.advantages-of-zoom {
    margin-top: 140px;
    text-align: center;

    &__container {
        margin-top: 32px;
        background-image: url('../../../../components/images/ContactBackground.png');
        background-repeat: repeat-y;
        background-position: center;
        background-position-y: -60px;

        &__inner {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 24px;
            grid-row-gap: 40px;
            padding: 24px 0;

            &__item {
                // background-color: white;
                border-radius: 10px;
                transition: all .3s ease-out;
                background: linear-gradient(139.87deg, #71d1f2 12.39%, #c7c66d 22.08%, #f4bb49 31.48%, #eb874e 42.64%, #eb874e 50%, #ffffff 50%) right bottom/300% 100% no-repeat;

                &:hover {
                    background-position: left bottom;
                    transform: scale(1.05);
                }

                img {
                    width: 100%;
                    height: auto;
                }

                h3 {
                    padding: 16px 0;
                }
            }
        }
    }

    h1 {
        color: white;
    }

    @media all and (max-width: 720px) {
        margin-top: 48px;

        &__container {
            &__inner {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 32px;

        &__container {
            margin-top: 16px;

            &__inner {
                grid-column-gap: 16px;
                grid-row-gap: 24px;

                &__item {
                    h3 {
                        padding: 8px 8px;
                    }
                }
            }
        }
    }
}