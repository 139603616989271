.price {
    margin-top: 8.75em;

    .price-list {
        &__container {
            &__item {
                height: 478px;

                &__inside {
                    height: 476px;

                    &__content {
                        h5 {
                            height: 71px;
                            width: 265px;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 750px) {
        margin-top: 3em;

        .price-list {
            &__container {
                &__item {
                    height: 276px;

                    &__inside {
                        height: 274px;
                    }
                }
            }
        }
    }

    @media all and (max-width: 520px) {
        .price-list {
            &__container {
                &__item {
                    height: 262px;

                    &__inside {
                        height: 260px;
                    }
                }
            }
        }
    }

    @import '../../../../hooks/priceList/PriceList';
}