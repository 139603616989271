.stars-of-profession {
    margin-top: 8.75em;
    color: white;
    text-align: center;

    &__container {
        margin-top: 3.125em;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5em;


        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.875em;

            img {
                width: 120px;
                height: 120px;
            }
        }
    }

    @media all and (max-width: 720px) {
        margin-top: 3em;

        &__container {
            margin-top: 2em;
            gap: 0.5em;


            &__item {
                gap: 1em;

                img {
                    width: 5em;
                    height: 5em;
                }
            }
        }
    }

    @media all and (max-width: 520px) {
        &__container {
            &__item {
                gap: 0.5em;
            }
        }
    }
}