.steps-of-development {
    margin-top: 140px;

    h1,
    p {
        color: white;
    }

    h1 {
        margin-bottom: 10px;
    }

    h2 {
        margin-bottom: 30px;
        color: #BEBEBE;
    }

    @media all and (max-width: 648px) {
        margin-top: 35px;

        h1 {
            margin-bottom: 5px;
        }

        h2 {
            margin-bottom: 15px;
        }
    }

}