.page {
    margin-top: 100px;
    margin-bottom: 100px;

    @media all and (max-width: 1115px) {
        margin-top: 60px;
        margin-bottom: 80px;
        gap: 20px;
    }

    @media all and (max-width: 520px) {
        margin-top: 30px;
        margin-bottom: 60px;
    }

    @import '../../hooks/ourProjects/ourProjects';
}