.App {
  max-width: 1115px;
  margin: 0 auto;

  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    margin: 0;
  }

  .h1-two {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    margin: 0;
  }

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    margin: 0;
  }

  .h2-two {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    margin: 0;
  }

  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }

  .h3-two {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 115%;
    margin: 0;
  }

  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
  }

  h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }

  .h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  p.name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    margin: 0;
  }

  .bold {
    font-weight: bold;
  }

  button {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.32px;

    border-radius: 50px;
    width: 200px;
    height: 60px;

    cursor: pointer;
  }

  .gradient {
    background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
    padding: 0;

    &__inner {
      color: white;
      background: linear-gradient(139.87deg, #ffffff 50%, #242423 50%) right bottom/250% 100% no-repeat;
      transition: all .3s ease-out;

      height: 56px;
      border-radius: 50px;

      display: grid;
      align-items: center;
    }

    &:hover {
      // background: white;

      .gradient__inner {
        color: #242423;
        // background: white;
        background-position: left bottom;
      }
    }
  }

  .btn-white {
    // background-color: white;
    border: 2px solid white;
    transition: all .3s ease-out;
    background: linear-gradient(139.87deg, #71d1f2 12.39%, #c7c66d 22.08%, #f4bb49 31.48%, #eb874e 42.64%, #eb874e 50%, #ffffff 50%) right bottom/250% 100% no-repeat;

    span {
      color: #242423;
    }

    &:hover {
      border-color: #242424;
      background-position: left bottom;

      span {
        color: black;
      }
    }
  }

  .btn-trsp {
    box-sizing: border-box;
    background-color: transparent;

    border: 2px solid #BEBEBE;
    border-radius: 50px;

    display: flex;
    justify-content: space-between;

    transition: all .3s ease-out;
    background: linear-gradient(139.87deg, #71d1f2 12.39%, #c7c66d 22.08%, #f4bb49 31.48%, #eb874e 42.64%, #eb874e 50%, #242424 50%) right bottom/250% 100% no-repeat;

    span {
      color: white;
      padding-right: 25px;
    }

    &:hover {
      border-color: #242424;
      background-position: left bottom;
    }
  }

  .dots {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;
    gap: 12px;

    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-radius: 10px;
      height: 12px;
      width: 12px;

      /* Inactive dot */
      background: #BEBEBE;

      flex: none;
      order: 1;
      flex-grow: 0;

      cursor: pointer;
    }

    .active {
      width: 40px;

      background: #FFFFFF;
    }
  }

  .images {
    display: flex;
    gap: 20px;

    img {
      width: 130px;
      height: 40px px;
      cursor: pointer;
    }
  }

  @media all and (max-width: 1115px) {
    padding: 0 15px;
  }

  @media all and (max-width: 520px) {
    width: auto;

    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      margin: 0;
    }

    .h1-two {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      margin: 0;
    }

    h2 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      margin: 0;
    }

    .h2-two {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 115%;
      margin: 0;
    }

    h3 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      margin: 0;
    }

    .h3-two {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 115%;
      margin: 0;
    }

    h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 18px;
      margin: 0;
    }

    h5 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      margin: 0;
    }

    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      margin: 0;
    }

    p.name {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      margin: 0;
    }

    .dots {
      li {
        height: 5px;
        width: 5px;
      }

      .active {
        width: 15px;
      }
    }

    button {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;

      width: 172px;
      height: 36px;
      border-radius: 36px;
    }

    .gradient {
      &__inner {
        height: 32px;
      }
    }

    .btn-trsp {
      img {
        width: 24px;
        height: 24px;
      }
    }

    .images {
      gap: 10px;

      img {
        width: 60px;
        height: 24px;
      }
    }
  }
}

.swal-button {
  background: white;
  border: 2px solid #242424;
  color: #242424;
}

.swal-button:not([disabled]):hover {
  background: #242424;
  color: white;
}

.swal-icon--success__line.swal-icon--success__line--long,
.swal-icon--success__line.swal-icon--success__line--tip {
  background-color: #242424;
}