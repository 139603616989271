.for-who {
    margin-top: 8.75em;

    .tick-container {
        &__container {
            margin: 3.75em 0;
            grid-row-gap: 3.125em;
            grid-column-gap: 7.5em;
        }

        .gradient {
            &__inner {
                width: 31em;
            }
        }
    }

    @media all and (max-width: 750px) {
        margin-top: 3em;

        .tick-container {
            &__container {
                margin-top: 2em;
                margin-bottom: 1.5em;

                grid-row-gap: 1em;
                grid-column-gap: 1em;
            }

            .gradient {
                &__inner {
                    width: 266px;
                }
            }
        }
    }

    @media all and (max-width: 520px) {
        .tick-container {
            .gradient {
                &__inner {
                    width: 221px;
                }
            }
        }
    }

    @import '../../../../hooks/tickContainer/_TickContainer';
}