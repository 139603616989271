.who-made-a-course {
    margin-top: 8.75em;
    color: white;

    h1 {
        text-align: center;
    }

    &__container {
        margin-top: 3.75em;
        display: flex;

        &__left {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            img {
                width: 356px;
                height: 404px;
                border-radius: 20px;
            }

            .name {
                margin-top: 1.875em;
                margin-bottom: 1em;
            }

            p {
                width: 80%;
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            gap: 3.75em;

            &__item {
                display: flex;
                gap: 1.875em;
                align-items: center;


                img {
                    height: 2.625em;
                    width: 2.625em;
                }
            }
        }
    }

    @media all and (max-width: 1115px) {
        &__container {
            flex-direction: column;
            gap: 3.75em;
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 3em;

        &__container {
            margin-top: 2em;
            gap: 2em;

            &__left {
                .name {
                    margin-top: 1em;
                    margin-bottom: 0.5em;
                }
            }

            &__right {
                gap: 1.5em;

                &__item {
                    gap: 0.5em;

                    img {
                        width: 1.5em;
                        height: 1.5em;
                    }
                }
            }
        }
    }
}