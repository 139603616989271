.tick-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;

    h1 {
        text-align: center;
    }

    &__container {
        display: grid;
        grid-template-columns: auto auto;

        &__item {
            display: flex;
            gap: 1.875em;
            align-items: center;

            img {
                height: 2.625em;
                width: 2.625em;
            }
        }
    }

    button {
        color: #333333;
        width: 31.25em;

        border: 2px solid white;
        transition: all .2s ease-out;
        // background: linear-gradient(139.87deg, #71d1f2 24.78%, #c7c66d 44.16%, #f4bb49 62.95%, #eb874e 85.28%), #d9d9d9;
        background: linear-gradient(139.87deg, #71d1f2 12.39%, #c7c66d 22.08%, #f4bb49 31.48%, #eb874e 42.64%, #eb874e 50%, #ffffff 50%) right bottom/250% 100% no-repeat;

        &:hover {
            border: none;
            background-position: left bottom;
        }
    }

    @media all and (max-width: 750px) {

        &__container {
            grid-row-gap: 1em;
        }

        button {
            width: 15em;
        }
    }

    @media all and (max-width: 520px) {
        &__container {
            grid-template-columns: auto;

            &__item {
                gap: 0.5em;

                img {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }
}