.why-becomve-ios-dev {
    margin-top: 8.75em;

    color: white;

    h1 {
        text-align: center;
    }

    &__container {
        margin-top: 3.75em;
        display: flex;
        flex-direction: column;
        gap: 3.125em;

        &__item {
            display: flex;
            gap: 1.875em;
            align-items: center;

            img {
                height: 2.625em;
                width: 2.625em;
            }
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 3em;

        &__container {
            margin-top: 2em;
            gap: 1.5em;

            &__item {
                gap: 0.5em;

                img {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }

}