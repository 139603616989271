.study-page {
    margin-top: 100px;
    margin-bottom: 100px;

    .landing-text {
        h1 {
            margin-bottom: 80px;
        }
    }

    .transp-container {
        .container {
            margin-top: 160px;
        }
    }

    @media all and (max-width: 1115px) {
        margin-top: 60px;
        margin-bottom: 80px;

        .landing-text {
            h1 {
                margin-bottom: 40px;
            }
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 30px;
        margin-bottom: 60px;

        .transp-container {
            .container {
                margin-top: 50px;
            }
        }
    }

    @import './components/secondPart/SecondPart',
    './components/whatIWillLearn/WhatIWillLearn',
    './components/whatsIncluded/WhatsIncluded.scss',
    './components/Price/Price',
    './components/whoMadeACourse/WhoMadeACourse',
    './components/whyBecomeIOSDev/WhyBecomeIOSDev',
    './components/forWho/ForWho',
    './components/starsOfProfession/StarsOfProfession',
    './components//demandsForStudents//DemandsForStudents',
    '../../hooks/landingText/LandingText',
    '../../hooks/transpContainer/transpContainer.scss';
}