.all-steps {
    margin-top: 127px;
    margin-bottom: 76px;

    .upper-part,
    .lower-part {
        color: #E6E4E8;

        .items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            justify-items: stretch;
            gap: 5px;

            .item {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;

                .circle {
                    width: 6em;
                    height: 6em;
                    border-radius: 50%;

                    background: #E6E4E8;

                    display: grid;
                    align-content: center;
                    justify-content: center;

                    .inner {
                        width: 5.64em;
                        height: 5.64em;
                        border-radius: 50%;

                        background: #242423;

                        display: grid;
                        align-content: center;
                        justify-content: center;

                        h1 {
                            font-weight: 500;
                        }
                    }
                }
            }

            .active {
                .circle {
                    background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                }

                .h3-two,
                .circle.inner.h1 {
                    background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }

            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;

                line {
                    stroke-width: 2px;
                    stroke-dasharray: 20px 10px;
                    stroke: #E6E4E8;
                }
            }
        }

        .lines {
            margin: 10px 0;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            justify-items: stretch;

            .line {
                display: flex;
                justify-content: center;

                img {
                    visibility: hidden;
                }

                .active {
                    visibility: visible;
                }
            }
        }
    }

    .main-part {
        border-radius: 3em;
        background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;

        width: 100%;
        height: 27em;
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
            border-radius: 3em;
            background: #242423;
            color: white;

            width: 99.6%;
            height: 99.3%;
            display: flex;
            justify-content: center;
            align-items: center;

            .text {
                padding: 4em 5.4em;

                ul {
                    margin: 0;
                }
            }
        }
    }

    @media all and (max-width: 648px) {

        .h3-two {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            line-height: 115%;
            margin: 0;
        }

        margin-top: 35px;
        margin-bottom: 60px;

        .upper-part,
        .lower-part {
            .items {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                justify-items: stretch;

                .item {

                    .circle {
                        width: 3em;
                        height: 3em;

                        .inner {
                            width: 2.7em;
                            height: 2.7em;
                        }
                    }
                }
            }

            .lines {
                margin: 5px 0;

                .line {
                    img {
                        height: 50px;
                    }
                }
            }
        }

        .main-part {
            height: 22em;

            .container {
                width: 99.0%;
                height: 98.7%;

                .text {
                    padding: 2em 2em;
                }
            }
        }
    }
}