.landing-text {
    color: white;

    h1 {
        text-align: center;
    }

    ul {
        padding-inline-start: 1em;

        li:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    @media all and (max-width: 520px) {
        h1 {
            margin-bottom: 15px;
        }

        ul {
            li:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}