.why-clients-choose-zoom {
    margin-top: 140px;
    text-align: center;
    color: white;

    &__container {
        margin-top: 32px;

        .slick-list {
            padding: 0 12.5% !important;
        }

        .slick-arrow {
            width: 20px;
            height: 20px;
            top: 120%;
        }

        .slick-prev {
            left: 40%;
        }

        .slick-next {
            right: 40%;
        }

        &__item {
            img {
                width: 120px;
                height: 120px;
                border-radius: 100px;
                margin: 0 auto;
            }

            h3 {
                padding: 0 12px;
                margin-top: 30px;
            }
        }
    }

    @media all and (max-width: 750px) {
        &__container {
            .slick-arrow {
                top: 105%;
            }
        }
    }

    @media all and (max-width: 720px) {
        margin-top: 48px;

        &__container {
            margin-top: 32px;

            .slick-list {
                padding: 0 25% !important;
            }
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 32px;

        &__container {
            margin-top: 16px;

            &__item {
                img {
                    width: 5em;
                    height: 5em;
                }

                h3 {
                    padding: 0 6px;
                    margin-top: 15px;
                }
            }

            .slick-list {
                padding: 0 12% !important;
            }

            .slick-arrow {
                top: 115%;
            }
        }
    }
}