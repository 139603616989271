.our-products {
    margin-top: 8.75em;

    .price-list {
        &__container {
            &__item {
                height: 588px;

                &__inside {
                    height: 586px;

                    &__content {
                        h5 {
                            width: 328px;
                            height: 176px;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 750px) {
        margin-top: 3em;

        .price-list {
            &__container {
                &__item {
                    height: 381px;

                    &__inside {
                        height: 379px;

                        &__content {
                            h5 {
                                width: 328px;
                                height: 176px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 520px) {
        .price-list {
            &__container {
                &__item {
                    height: 321px;

                    &__inside {
                        height: 319px;

                        &__content {
                            h5 {
                                width: 328px;
                                height: 140px;
                            }
                        }
                    }
                }
            }
        }
    }

    @import '../../../../hooks/priceList/PriceList';
}