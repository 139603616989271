.price-list {
    color: white;
    text-align: center;

    &__container {
        margin-top: 3.75em;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 1.5em;

        &__item {
            width: 356px;
            // height: 478px;
            background: linear-gradient(270deg, rgba(186, 132, 88, 0.26) 0%, #BA8458 19.21%, #C19A59 37.72%, #6EABBD 82.83%, rgba(110, 171, 189, 0.24) 100%);
            display: grid;
            align-items: center;
            justify-content: center;
            border-radius: 10px;

            &__inside {
                width: 354px;
                // height: 476px;
                background: #242423;
                border-radius: 10px;

                &__content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    p.name {
                        margin-top: 36px;
                        height: 60px;
                    }

                    hr {
                        margin: 0;
                        margin-top: 28px;
                        width: 166px;
                        height: 2px;
                        border: none;
                        background: linear-gradient(270deg, rgba(186, 132, 88, 0.26) 0%, #BA8458 19.21%, #C19A59 37.72%, #6EABBD 82.83%, rgba(110, 171, 189, 0.24) 100%);
                    }

                    .h1-two {
                        margin-top: 40px;
                        height: 46px;
                    }

                    h5 {
                        margin-top: 70px;
                        // height: 71px;
                        // width: 265px;
                    }

                    .gradient {
                        margin-top: 36px;
                        width: 330px;

                        &__inner {
                            width: 326px;
                        }
                    }
                }

                &:hover {
                    background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%);
                    transform: scale(1.05);

                    .gradient {
                        background: white;

                        .gradient__inner {
                            color: #242423;
                            background-position: left bottom;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 1115px) {
        &__container {
            grid-template-columns: 1fr;
            justify-items: center;
            gap: 20px;
        }
    }

    @media all and (max-width: 750px) {
        &__container {
            margin-top: 2em;

            &__item {
                width: 356px;
                // height: 262px;

                &__inside {
                    width: 354px;
                    // height: 260px;
                    border-radius: 10px;

                    &__content {
                        p.name {
                            margin-top: 16px;
                            height: 30px;
                        }

                        hr {
                            margin-top: 10px;
                            width: 320px;
                        }

                        .h1-two {
                            margin-top: 20px;
                            height: 20px;
                        }

                        h5 {
                            margin-top: 24px;
                        }

                        .gradient {
                            margin-top: 16px;
                            width: 330px;

                            &__inner {
                                width: 326px;
                            }
                        }
                    }
                }
            }
        }
    }
}