form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    input {
        width: 480px;
        background: white;
        border-radius: 50px;

        padding: 18px 0 18px 20px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin: 0;

        border: none;

        color: #A4A4A4;

        transition: all 0.1s;

        &:first-child:focus {
            outline: 3px solid #71d1f2;
        }

        &:nth-child(2):focus {
            outline: 3px solid #eb874e;
        }
    }

    .submit {
        cursor: pointer;
        display: grid;

        width: 500px;
        padding: 12.5px 0 12.5px 0;

        font-family: 'Euclid Circular A';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 33px;

        letter-spacing: -0.32px;

        color: #242423;

        transition: all .2s ease-out;
        background-size: 200% 100%;
        background-position: right bottom;
        background: linear-gradient(139.87deg, #71d1f2 12.39%, #c7c66d 22.08%, #f4bb49 31.48%, #eb874e 42.64%, #eb874e 50%, #ffffff 50%) right bottom/250% 100% no-repeat;

        &:hover {
            border-color: #242424;
            background-position: left bottom;

            span {
                color: black;
            }
        }
    }

    @media all and (max-width: 680px) {

        input {
            width: 80%;
            padding-left: 4%;
        }

        .submit {
            width: 84%;
        }
    }

    @media all and (max-width: 520px) {
        gap: 15px;

        input {
            width: 80%;
            border-radius: 36px;
            padding: 10px 0;
            padding-left: 5%;

            font-size: 13px;
            line-height: 16px
        }

        .submit {
            width: 85%;
            font-size: 15px;
            line-height: 18px;
            padding: 9px 0 9px 0;
        }
    }
}