.whats-included {
    margin-top: 8.75em;
    color: white;

    h1 {
        text-align: center;
    }

    @media all and (max-width: 520px) {
        margin-top: 3em;
    }

    @import './DotsContent/DotsContent.scss',
    './DateAndTime/DateAndTime.scss';
}