.second-part {
    margin-top: 100px;

    .container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 90px;

        .item {
            display: grid;
            justify-items: center;
            gap: 16px;
            text-align: center;

            .h2-two {
                color: white;
            }
        }
    }

    @media all and (max-width: 648px) {
        margin-top: 60px;

        .container {
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 12px;
            grid-row-gap: 40px;

            .item {
                gap: 8px;

                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    @media all and (max-width: 520px) {
        margin-top: 40px;

        .container {
            .item {
                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}