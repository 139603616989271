.what-i-will-learn {
    padding-top: 3.125em;
    padding-bottom: 2.5em;

    .tick-container {
        &__container {
            margin-top: 3.125em;
            margin-bottom: 4.375em;
            padding: 0 6.625em;
            grid-row-gap: 3.625em;
            grid-column-gap: 2.875em;
        }
    }

    @media all and (max-width: 750px) {
        padding-top: 1.25em;
        padding-bottom: 1.25em;

        .tick-container {
            &__container {
                margin-top: 1.25em;
                margin-bottom: 1.25em;
                padding: 0 0.5em;

                grid-template-columns: auto;
            }
        }
    }

    @import '../../../../hooks/tickContainer/_TickContainer';
}