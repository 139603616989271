.footer {
    hr {
        margin: 0;
        height: 2px;
        border: none;
        background: linear-gradient(270deg, rgba(186, 132, 88, 0.26) 0%, #BA8458 19.21%, #C19A59 37.72%, #6EABBD 82.83%, rgba(110, 171, 189, 0.24) 100%);
    }

    .footer-container {
        padding: 70px 0;
        display: flex;
        justify-content: space-between;

        .contacts {
            padding: 20px 0;

            color: white;

            h1 {
                margin-bottom: 40px;
            }

            .list {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .item {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 10px;
                    }

                    a {
                        color: white;
                        text-decoration: none;

                        .slider {
                            width: 0;
                            height: 2px;
                            background: white;
                            transition: .3s ease-out;
                        }

                        &:hover {
                            .slider {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .map {
            img {
                width: 483px;
                height: 314px;
                border-radius: 20px;
                position: fixed;
            }
        }
    }

    .footer-container.contacts {
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 0;
        margin-top: 70px;

        .contacts {
            padding: 0;

            .list {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }

        .map {
            margin-top: 16px;

            img {
                width: 100%;
                height: auto;
                position: initial;
            }
        }
    }

    @media all and (max-width: 1200px) {
        .footer-container {
            padding: 30px 15px 15px 15px;
        }

        .footer-container.contacts {
            padding: 0;
            margin-top: 60px;
        }
    }

    @media all and (max-width: 750px) {
        .footer-container {
            padding: 15px 0;
            flex-direction: column;

            .contacts {
                padding: 15px 0 20px 0;

                h1 {
                    margin-bottom: 15px;
                }

                .list {
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-gap: 13px 25px;
                    justify-content: start;

                    .item {

                        img {
                            width: 18px;
                            height: 18px;
                            margin-right: 8px;
                        }
                    }

                    .first {
                        grid-column: 1;
                        grid-row: 1;
                    }

                    .second {
                        grid-column: 1;
                        grid-row: 2;
                    }

                    .third {
                        grid-column: 2;
                        grid-row: 2;
                    }

                    .fourth {
                        grid-column: 2;
                        grid-row: 1;
                    }
                }
            }

            .map {
                img {
                    width: 483px;
                    height: 314px;
                    border-radius: 20px;
                    position: fixed;
                }
            }
        }

        .footer-container.contacts {
            padding: 0;
            margin-top: 30px;

            .contacts {
                .list {
                    grid-template-columns: auto auto;
                }
            }
        }
    }
}