.leave-request {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        display: grid;
        place-items: center;
        visibility: hidden;
        z-index: -10;

        &__container {
            background: #242424;
            border-radius: 60px;
            width: 926px;

            &__close-button {
                width: 32px;
                height: 32px;
                cursor: pointer;
                float: right;
                position: relative;
                top: 40px;
                right: 63px;
            }

            &__content {
                padding: 40px 95px;
                padding-right: 63px;
                display: flex;
                flex-direction: column;
                height: 100%;
                align-items: center;
                gap: 24px;

                .h1-two {
                    text-align: center;
                    color: white;
                }
            }

            @media all and (max-width: 960px) {
                border-radius: 30px;
                width: 648px;
                height: auto;

                &__close-button {
                    position: relative;
                    top: 25px;
                    right: 25px;
                    width: 14px;
                    height: 14px;
                }

                &__content {
                    padding: 25px 0 25px 15px;
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    align-items: center;
                }
            }

            @media all and (max-width: 680px) {
                border-radius: 30px;
                width: 520px;

                &__content {
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    align-items: center;

                    form {
                        width: 100%;
                        // input {
                        //     width: 80%;
                        //     padding-left: 4%;
                        // }

                        // .submit {
                        //     width: 84%;
                        // }
                    }
                }
            }

            @media all and (max-width: 520px) {
                width: 315px;

                &__close-button {
                    top: 12px;
                    right: 12px;
                }
            }
        }
    }

    &__overlay.open-content {
        visibility: visible;
        z-index: 10;
    }
}