.main-page {
    .first-container {

        display: flex;
        margin-top: 70px;

        .left {

            margin-top: 30px;

            .logo {
                img {
                    width: 356px;
                    height: 140.51px;
                }
            }

            .content {

                margin-top: 67px;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .header {
                    h1 {
                        // width: 546px;
                        // height: 92px;

                        background: linear-gradient(90deg, #71D1F2 0%, #C7C66D 32.01%, #F4BB49 55.07%, #EB874E 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }

                .text {
                    h2 {
                        color: #BEBEBE;
                    }
                }

                .buttons {
                    display: flex;
                    gap: 30px;

                    a {
                        text-decoration: none;
                    }
                }
            }
        }

        .right {
            img {
                width: 546px;
                height: 546px;
            }
        }

        @media all and (max-width: 1200px) {
            flex-direction: column-reverse;
            margin-top: 20px;
            gap: 25px;

            .right {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                img {
                    align-self: center;
                    width: 68%;
                    height: auto;
                    max-width: 546px;
                    max-height: 546px;
                }
            }

            .left {
                margin-top: 25px;

                .logo {
                    display: none;
                }

                .content {
                    margin-top: 0;
                    gap: 0;

                    .text {
                        margin-top: 10px;
                        margin-bottom: 25px;
                    }

                    .buttons {
                        gap: 15px;
                    }
                }
            }
        }

        @media all and (max-width: 520px) {
            .left {
                .content {
                    .text {
                        h2 {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .about-us {
        margin-top: 200px;
        background-image: url('../../components/images/Rectangle\ 81.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        // width: 1116px;
        width: 100%;
        height: 462px;

        .circle {
            position: relative;
            width: 174px;
            height: 174px;
            top: -29px;
            right: 16px;
            float: right;

            border-radius: 50%;

            .rainbow-background {
                height: 100%;
                border-radius: 50%;
                background: linear-gradient(139.87deg, #71D1F2 24.78%, #C7C66D 44.16%, #F4BB49 62.95%, #EB874E 85.28%), #D9D9D9;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .text {
                width: 161px;
                height: 161px;
                background: #242423;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    width: 60px;
                    height: 95px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 88.9%;
                    /* or 21px */

                    display: flex;
                    text-align: center;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;

                    /* white */

                    color: #FFFFFF;

                    .big-15 {
                        font-size: 64px;
                    }
                }
            }
        }

        .frame-black {
            color: #333333;
            padding-top: 86px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 32px;

            left: 87px;
            position: relative;

            p {
                width: 926px;
                height: 249px;
            }
        }

        .expand-more-about-us {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 10px;

            float: right;
            margin-right: 20px;
            cursor: pointer;

            color: #333333;

            img {
                width: 18px;
                height: 18px;
                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
            }

            h2 {
                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }

        @media all and (max-width: 1200px) {
            height: auto;
            margin-top: 90px;
            width: 100%;
            background-size: cover;

            .frame-black {
                p {
                    width: auto;
                    height: auto;
                }
            }

            .expand-more-about-us {
                float: initial;
                justify-content: flex-end;
            }
        }

        @media all and (max-width: 520px) {
            margin-top: 60px;
            background-size: contain;
            background-image: url('../../components/images/Rectangle\ 81\ phone.png');

            .circle {
                width: 100px;
                height: 100px;
                top: -29px;
                right: 62px;

                .text {
                    width: 90px;
                    height: 90px;

                    span {
                        width: 30px;
                        height: 52px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 88.9%;

                        .big-15 {
                            font-size: 32px;
                        }
                    }
                }
            }

            .frame-black {
                width: 100%;
                gap: 15px;
                padding-top: 0;
                left: 0;
                top: -30px;

                p {
                    width: 262px;

                    span {
                        font-weight: 500;
                    }
                }
            }

            .expand-more-about-us {
                gap: 5px;
                margin-right: 16%;
                margin-top: -22px;

                img {
                    width: 13px;
                    height: 13px;
                }

                h2 {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 33px;
                }
            }
        }
    }

    .why-us {
        margin-top: 80px;
        margin-bottom: -96px;

        .arrow {
            top: -48px;
            position: relative;
            left: 175px;
        }

        .container {

            display: flex;
            flex-direction: column;
            align-items: center;

            color: white;

            top: -96px;
            position: relative;

            .list {
                margin-top: 60px;
                display: flex;
                flex-direction: column;
                height: 530px;
                width: 925px;
                justify-content: space-between;

                .item {
                    display: flex;
                    align-items: center;
                    gap: 30px;

                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }

        @media all and (max-width: 1200px) {
            .container {
                .list {
                    width: 100%;
                    height: auto;
                    gap: 33.33px;
                }
            }

            margin-top: 30px;
        }

        @media all and (max-width: 520px) {
            margin-top: 10px;

            .container {
                top: -30px;
                padding-bottom: 30px;

                .list {
                    margin-top: 15px;
                    width: 100%;
                    height: auto;
                    gap: 33.33px;

                    .item {
                        gap: 10px;

                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }

            .arrow {
                width: 70px;
                top: -5px;
                left: 80px;
                position: relative;
                height: auto;
            }
        }
    }

    .our-projects {
        margin-top: 140px;

        @media all and (max-width: 1200px) {
            margin-top: 60px;
        }
    }

    .transp-container {
        margin-top: 100px;
        margin-bottom: 140px;
        height: 739px;

        .container {
            margin-top: 120px;

            .h1-two {
                width: 864px;
                padding-top: 91px;
                display: block;
                text-align: center;

                color: white;
            }

            form {
                margin-top: 50px;
                padding-bottom: 91px;
            }
        }

        @media all and (max-width: 1200px) {
            margin-top: 60px;
            margin-bottom: 60px;
            height: 500px;

            .container {
                margin-top: 45px;
                width: 80%;

                .h1-two {
                    margin: 0 auto;
                    width: 90%;
                    padding-top: 20px;
                }

                form {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }

        @media all and (max-width: 520px) {
            margin-top: 2em;
            height: 301px;
            background-size: contain;

            .container {
                margin-top: 45px;
                width: 345px;

                .h1-two {
                    margin: 0 auto;
                    width: 315px;
                    padding-top: 20px;
                }

                form {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }

    @import '../../hooks/ourProjects/ourProjects',
    '../../hooks/transpContainer/transpContainer.scss';
}